import React, { useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import { isLoggedIn } from "../services/auth"
import { isEmpty } from "lodash"
import LOGIN from "../mutations/login"
import API from "../services/api"
import { v4 } from "uuid"
import { setUser } from "../services/auth"
import { useMutation, useQuery } from "@apollo/client"
import CircularProgress from "@material-ui/core/CircularProgress"
import { getUserRole } from "../query/userRoles"

import {
  Container,
  Box,
  Grid,
  Checkbox,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
  TextField,
  CssBaseline,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
    border: "1px solid #ccc",
    boxShadow: "0 0 20px rgba(0,0,0,0.19), 0 0 20px rgba(0,0,0,0.19)",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Login = () => {
  isLoggedIn() && navigate(`/`)
  const [userData, setUserData] = useState(null)

  const [userInfo, setUserInfo] = useState({
    username: ``,
    password: ``,
  })

  const [errorMessage, setErrorMessage] = useState(null)

  const classes = useStyles()

  const getUserRole = async id => {
    const { data } = await API.post("sce/get-user/roles", {
      userId: id,
    })
    console.log(data)
    setUserData(prevState => ({ ...prevState, roles: data }))
    setUser(userData)
    navigate(`/`)
  }

  // useEffect(() => {
  //   console.log("I am running")
  //   if (userData) {
  //     getUserRole(userData.id)
  //   }
  // }, [userData])

  // Login Mutation.
  const [login, { data, loading, error }] = useMutation(LOGIN, {
    variables: {
      input: {
        clientMutationId: v4(), // Generate a unique id.,
        username: userInfo.username,
        password: userInfo.password,
      },
    },
    onCompleted: data => {
      let { login } = data
      // setUserData(login)
      setUser(login)
      navigate(`/`)
    },
    onError: error => {
      if (error) {
        if (!isEmpty(error)) {
          setErrorMessage(error.graphQLErrors[0].message)
          console.log(errorMessage)
        }
      }
    },
  })

  const handleUpdate = event => {
    const { name, value } = event.target
    setUserInfo(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const loginHandler = event => {
    event.preventDefault()
    login()
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="username"
            autoComplete="email"
            onChange={e => handleUpdate(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => handleUpdate(e)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <div>
            {!loading ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={loginHandler}
              >
                Sign In
              </Button>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "1rem",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </div>
          <Grid container>
            <Grid item xs>
              <Link to="#" variant="body2">
                {"Forgot password?"}
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <a color="inherit" href="https://www.shoonya.co/">
            {"Shoonya"}
          </a>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </Container>
  )
}

export default Login
